function initMap() {
  // The location of Opus
  var opus = {lat: 60.394160, lng: 5.329678};
  var map_center = {lat: 60.394806, lng: 5.329678};
  // The map, centered at Opus
  var map = new google.maps.Map(
    document.getElementById('map'), {
      zoom: 15,
      center: map_center,
      disableDefaultUI: true,
      styles: [
        {
          "elementType":"geometry",
          "stylers":[{"color":"#2b2a29"}]
        },
        {
          "elementType":"labels.icon",
          "stylers":[{"visibility":"off"},{"weight":2}]
        },
        {
          "elementType":"labels.text.fill",
          "stylers":[{"color":"#84817c"}]
        },
        {
          "elementType":"labels.text.stroke",
          "stylers":[{"color":"#242f3e"}]
        },
        {
          "featureType":"administrative.locality",
          "elementType":"labels.text.fill",
          "stylers":[{"color":"#89847c"}]
        },
        {
          "featureType":"poi",
          "elementType":"labels.text.fill",
          "stylers":[{"color":"#d59563"}]
        },
        {
          "featureType":"poi.park",
          "elementType":"geometry",
          "stylers":[{"color":"#fff"}]
        },
        {
          "featureType":"poi.park",
          "elementType":"labels.text.fill",
          "stylers":[{"color":"#6b9a76"}]
        },
        {
          "featureType":"road",
          "stylers":[{"color":"#37342d"}]
        },
        {
          "featureType":"road",
          "elementType":"geometry",
          "stylers":[{"color":"#585451"}]
        },
        {
          "featureType":"road",
          "elementType":"geometry.stroke",
          "stylers":[{"color":"#212a37"}]
        },
        {
          "featureType":"road",
          "elementType":"labels.text.fill",
          "stylers":[{"color":"#9ca5b3"}]
        },
        {
          "featureType":"road.highway",
          "elementType":"geometry",
          "stylers":[{"color":"#746855"}]
        },
        {
          "featureType":"road.highway",
          "elementType":"geometry.stroke",
          "stylers":[{"color":"#1f2835"}]
        },
        {
          "featureType":"road.highway",
          "elementType":"labels.text.fill",
          "stylers":[{"color":"#f3d19c"}]
        },
        {
          "featureType":"transit",
          "elementType":"geometry",
          "stylers":[{"color":"#2f3948"}]
        },
        {
          "featureType":"transit.station",
          "elementType":"labels.text.fill",
          "stylers":[{"color":"#d59563"}]
        },
        {
          "featureType":"water",
          "elementType":"geometry",
          "stylers":[{"color":"#23586d"}]
        },
        {
          "featureType":"water",
          "elementType":"geometry.fill",
          "stylers":[{"color":"#191e23"}]
        },
        {
          "featureType":"water",
          "elementType":"labels.text.fill",
          "stylers":[{"color":"#515c6d"}]
        },
        {
          "featureType":"water",
          "elementType":"labels.text.stroke",
          "stylers":[{"color":"#17263c"}]
        }
      ]
    }
    );
  // The marker, positioned at Opus
  var local_pin = require ('../images/pin_opus.png');
  var marker = new google.maps.Marker({position: opus, map: map, icon: local_pin});

  // The info window
  var opus_logo = require ('../images/opus_logo.png');
  var contentString = '<div class="map_info_window"><img src="' + opus_logo + '" alt="Opus frisør"><p>Kong Oscars Gate 30<br/>5017 Bergen</p><strong>Telefon:</strong> 55 32 32 80</div>';
  var infowindow = new google.maps.InfoWindow({
    content: contentString
  });

  // Always open
  infowindow.open(map, marker);

  // Click to open
   marker.addListener('click', function() {
    infowindow.open(map, marker);
  });
}

initMap();